function Home() {
  return (
    <main>
      <img className="p-4 w-full" src={`/zziririt-logo.png`} alt="메인 배너" />
      <img className="w-full" src={`/main-banner.jpg`} alt="메인 배너" />
      {/*<img className="phoneImage" alt="iPhone_01" src="." />*/}
      {/*<Carousel />*/}
    </main>
  );
}

export default Home;
